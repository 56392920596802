import { logWarn } from 'logging';

/**
 * This function calculates if an event should be to send to sentry
 * or if we want to exclude it.
 *
 * @param {Object} event Sentry event
 * @param {Object} eventMatcher Matcher settings
 * @param {string} [eventMatcher.type] The event type. For example: TypeError
 * @param {string} [eventMatcher.value] The event message. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.message] The event message in case is not an exception. For example: Cannot read properties of undefined
 * @param {string} [eventMatcher.file] The source file path or string. For example: http://bentobox.com/file.js or just file.js
 * @param {string} [eventMatcher.missingStackTrace] If set to true, the expectation is that the event doesn't have a stack trace, if set to false (the default), the event must have a stacktrace.
 *
 * @returns boolean
 */
export function excludeEvent(event, eventMatcher) {
  // Usage restrictions
  const matcherProperties = Object.getOwnPropertyNames(eventMatcher);
  if (
    matcherProperties.includes('file') &&
    matcherProperties.includes('missingStackTrace')
  ) {
    throw Error(
      'This two properties can not be set at the same time, please only use one: "file", "missingStackTrace"'
    );
  }

  try {
    if (event.exception) {
      let typeAndValueMatches = false;
      let fileMatches = false;
      let passesStackTraceCheck = true;

      const sentryExcept =
        event.exception.values[event.exception.values.length - 1];

      if (eventMatcher.missingStackTrace && sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (!eventMatcher.missingStackTrace && !sentryExcept.stacktrace) {
        passesStackTraceCheck = false;
      }

      if (eventMatcher.type && eventMatcher.value) {
        const typeMatches = sentryExcept.type.includes(eventMatcher.type);
        const valueMatches = sentryExcept.value.includes(eventMatcher.value);
        typeAndValueMatches = typeMatches && valueMatches;
      }

      if (eventMatcher.file) {
        const frame =
          sentryExcept.stacktrace?.frames[
            sentryExcept.stacktrace?.frames.length - 1
          ];
        if (frame) {
          const filenameMatches = (frame.filename ?? '').includes(
            eventMatcher.file
          );
          const absPathMatches = (frame.abs_path ?? '').includes(
            eventMatcher.file
          );
          fileMatches = filenameMatches || absPathMatches;
        }
        return typeAndValueMatches && fileMatches;
      }

      return typeAndValueMatches && passesStackTraceCheck;
    } else {
      if (eventMatcher.message && event.message) {
        return event.message.includes(eventMatcher.message);
      }
    }
  } catch (error) {
    return false;
  }
  return false;
}

export function beforeSend(event) {
  const ignoreEvent = excludeEventMatcherList.some(matcher =>
    excludeEvent(event, matcher)
  );

  if (ignoreEvent) {
    // Extract exception details or fallback to a message
    const sentryExcept = event.exception?.values?.at(-1) || {
      type: 'Message',
      value: event.message || 'Unknown exception'
    };

    // Log the exception for debugging
    logWarn(
      `[Online-Ordering] Unhandled Exception: [${sentryExcept.type ||
        'Unknown Type'}][${sentryExcept.value || 'Unknown Value'}]`,
      { eventId: event.event_id, tags: event.tags }
    );

    // Return null to ignore the event
    return null;
  }
  return event;
}

/**
 * If you want to add a new matcher,
 * please make sure all test pass on "sentryUtils.test.js" file.
 *
 * Also, don't forget to add an example of the event on "excludeEventRealEvents.js" file,
 * The test will automatically pick it up and run the test applying the matchers.
 */
export const excludeEventMatcherList = [
  {
    // https://bentobox.sentry.io/issues/2460602068/
    type: 'UnhandledRejection',
    value: 'Non-Error promise rejection captured with value: undefined',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/2460602068/
    type: 'UnhandledRejection',
    value:
      'Non-Error promise rejection captured with value: Object Not Found Matching',
    missingStackTrace: true
  },
  {
    // https://bentobox.sentry.io/issues/4055282825/
    type: 'TypeError',
    value: "undefined is not an object (evaluating 'n.length')"
  },
  {
    // https://bentobox.sentry.io/issues/6065204059/
    type: 'TypeError',
    value: "undefined is not an object (evaluating 't.ssresp')",
    file: 'origin-secure-prod-radware.getbento.com'
  },
  {
    // https://bentobox.sentry.io/issues/5940192574/events/2fbe4b9765744d108d097f9225a20241/
    message:
      "Cannot read properties of undefined (reading 'catering_fulfillment_method') [object Object] TypeError: Cannot read properties of undefined (reading 'catering_fulfillment_method')"
  },
  {
    // https://bentobox.sentry.io/issues/5940192574/events/565676696d094629b8d954b6bbee97c3/
    message:
      "Cannot read properties of undefined (reading 'fulfillment_date') [object Object] TypeError: Cannot read properties of undefined (reading 'fulfillment_date')"
  },
  {
    // https://bentobox.sentry.io/issues/3896228121/
    type: 'Error',
    value: 'Network Error'
  },
  {
    // https://bentobox.sentry.io/issues/1581838644/?project=1513815
    type: 'Error',
    value: 'Could not load "places_impl"',
    file: 'maps.googleapis.com'
  }
];
