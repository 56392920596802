import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { APPNAME, PRODUCTION, STAGING, SENTRY_DSN } from './constants';
import { getEnvironment } from './helpers';

import { beforeSend } from '../utils/sentryUtils';

function getRelease() {
  // The git tag inserted into index.html by CI for successful master branch builds.
  try {
    const tag = document
      .querySelector(`meta[name="getbento/${APPNAME}"]`)
      .getAttribute('content');

    return process.env.FULL_COMMIT_HASH || `${APPNAME}@${tag}`;
  } catch (e) {
    return `${APPNAME}@pre-production`;
  }
}

export function initializeSentry() {
  Sentry.init({
    // add DEVELOPMENT into the array below if you want sentry logging for local development
    dsn: [PRODUCTION, STAGING].includes(getEnvironment()) ? SENTRY_DSN : '',
    integrations: [
      new CaptureConsoleIntegration({
        levels: ['error']
      })
    ],
    environment: getEnvironment(),
    release: getRelease(),
    ignoreErrors: [
      `Can't find variable: jQuery`,
      'jQuery is not defined',
      'ChunkLoadError',
      'loadable-components: failed to asynchronously load component'
    ],
    beforeSend
  });

  Sentry.configureScope(scope => {
    scope.addEventProcessor(async event => {
      if (event.extra == null) {
        event.extra = {};
      }

      // Add the smartlook session
      // Build the URL to the session recording
      let sessionRecordingUrl = '';
      if (window.smartlook && window.smartlook.playUrl) {
        sessionRecordingUrl = window.smartlook.playUrl;
      }

      event.extra.sessionRecordingUrl = sessionRecordingUrl || 'not available';

      return event;
    });
  });
}
